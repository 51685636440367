<template>
  <NuxtLink :to="toLink" class="w-44 p-2">
    <AppImage v-if="image" :asset="image" class="max-h-full w-full" />
    <ShopSharedImage
      v-else
      :media="brand?.media"
      :alt="brand?.name"
      class="max-h-full w-full"
    />
  </NuxtLink>
</template>

<script setup lang="ts">
import type { Schemas } from '#shopware'
import { useShopRouter } from '#imports'
import { type AssetFragment } from '#gql'

const props = withDefaults(
  defineProps<{
    category: Schemas['Category']
    image?: AssetFragment
    link?: boolean
  }>(),
  {
    link: true,
  }
)

const { getCategoryRoute } = useShopRouter()

const toLink = computed(() => {
  if (!props.link) return undefined
  return getCategoryRoute(props.category)
})

const brand = computed(
  () => props.category?.extensions?.categoryManufacturer?.manufacturer
)
</script>
